
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Calendar from 'primevue/calendar';
import CheckBoxInput from '../CheckBoxInput.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { ExportRequest } from '@/models/Export';
import OverlayModal from '../Calendar/OverlayModal.vue';
import Swal from 'sweetalert2';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import Q from 'q';
import moment from 'moment';
import { AwaitTaskResponse, ExternalAccountingExportRequest, ExternalAccountingExportDocument } from '@/models/Interfaces';
import { ExportRequestStatus, ExternalAccountingExportDocumentType } from '@/models/Enums';
import { ApiResult } from '@/services/BaseApi';
import Multiselect from '@vueform/multiselect';
import { generalStore } from '@/store';
import { createDebounce } from '@/helper';

export default defineComponent({
    components: { TabPanel, TabView, Calendar, CheckBoxInput, OverlayModal, Multiselect },
    async setup() {
        const canExportPerfomaInvoices = computed(
            () => generalStore.state.defaultProviderSettings?.defaultProviderSettingsCanExportPerfomaInvoices
        );
        const perfomaInvoiceTitle = computed(() => generalStore.state.defaultProviderSettings?.defaultProviderSettingsPerfomaInvoiceTitle);
        const filter = ref<{
            documentNumber: string;
            documentType: ExternalAccountingExportDocumentType | null;
            status: ExportRequestStatus | null;
        }>({ documentNumber: '', documentType: null, status: null });
        const exportRequest = ref(new ExportRequest());
        const { t } = useI18n();

        const statusOptions = [
            { id: ExportRequestStatus.Canceled, name: t('export.status.canceled') },
            { id: ExportRequestStatus.Ok, name: t('export.status.ok') }
        ];

        const documentTypeOptions = [
            { id: ExternalAccountingExportDocumentType.Invoice, name: t('external-document-type.Invoice') },
            { id: ExternalAccountingExportDocumentType.Receipt, name: t('external-document-type.Receipt') }
        ];

        const history = ref<Array<ExternalAccountingExportRequest>>([]);

        const fromDate = ref(new Date());
        const toDate = ref(new Date());
        const openModal = ref(false);

        const selectedItem = ref<ExternalAccountingExportRequest>();

        const docs = ref<ExternalAccountingExportDocument[]>([]);

        function getDocumentTypeDisplay(id: ExternalAccountingExportDocumentType) {
            return t(`external-document-type.${ExternalAccountingExportDocumentType[id]}`);
        }

        function filterDocs() {
            docs.value = selectedItem.value?.exportDocuments ?? [];

            if (filter.value.status != null) {
                docs.value = docs.value.filter(x => x.active == !!filter.value.status);
            }
            if (filter.value.documentNumber) {
                docs.value = docs.value.filter(x => x.documentNumber.toString().startsWith(filter.value.documentNumber));
            }
            if (filter.value.documentType) {
                docs.value = docs.value.filter(x => x.documentType == filter.value.documentType);
            }
        }

        function selectItem(id: string) {
            selectedItem.value = history.value.find(x => x.id == id);
            filter.value.documentNumber = '';
            filter.value.documentType = null;
            filter.value.status = null;
            filterDocs();
            openModal.value = true;
        }

        const selectedAll = ref(false);

        watch(selectedAll, () => {
            if (selectedAll.value) {
                docs.value.forEach(x => (x.cancel = true));
            } else {
                docs.value.forEach(x => (x.cancel = false));
            }
        });

        async function getHistory() {
            const historyResponse = await api.getExportHistory();
            if (historyResponse.errorMessage) {
                await Swal.fire({
                    icon: 'error',
                    text: historyResponse.errorMessage
                });
            } else {
                history.value = historyResponse.data?.reverse() ?? [];
            }
        }

        await getHistory();

        async function awaitTask(
            action: () => Promise<ApiResult<AwaitTaskResponse>>,
            onSuccess: (url: string) => void,
            waitTime = 3000,
            fire = false
        ) {
            if (fire) {
                Swal.fire({ title: t('swalAction.processing'), allowOutsideClick: false });
                Swal.showLoading();
            }
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await Swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        async function exportDocument() {
            if (!exportRequest.value.invoice && !exportRequest.value.receipt && !exportRequest.value.perfomaInvoice) {
                Swal.fire({ icon: 'warning', title: t('alert.select-document-type') });
                return;
            }
            Swal.showLoading();
            exportRequest.value.startDate = moment(fromDate.value).format('YYYY-MM-DD');
            exportRequest.value.endDate = moment(toDate.value).format('YYYY-MM-DD');
            const delay = Q.delay(400);
            const response = await api.exportDocuments(exportRequest.value);
            await delay;
            if (response.errorMessage) {
                Swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            if (response.data?.error || !response.data) {
                Swal.fire({
                    icon: 'error',
                    text: response.data?.error || 'No data'
                });
                return;
            }
            const taskId = response.data?.taskId ?? '';
            awaitTask(
                () => api.waitExportAccountingTask(taskId),
                async url => {
                    Swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.accounting'),
                        html: `
                    <div class="text-center my-4">
                        <a href="${url}" download>
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                    await getHistory();
                },
                400,
                true
            );
        }

        async function cancelRequest() {
            Swal.showLoading();
            const delay = Q.delay(400);
            const response = await api.cancelExportRequest(selectedItem.value?.id ?? '');
            await delay;
            if (response.errorMessage) {
                Swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            const id = selectedItem.value?.id ?? '';
            await getHistory();
            selectItem(id);
            filterDocs();
            await Swal.fire({ icon: 'success', title: t('export.cancelled') });
        }

        async function cancelDocuments() {
            Swal.showLoading();
            const delay = Q.delay(400);
            const response = await api.cancelExportDocuments(
                selectedItem.value?.id ?? '',
                selectedItem.value?.exportDocuments.filter(x => x.cancel).map(x => x.id) ?? []
            );
            await delay;
            if (response.errorMessage) {
                Swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            const id = selectedItem.value?.id ?? '';
            await getHistory();
            selectItem(id);
            filterDocs();
            await Swal.fire({ icon: 'success', title: t('export.cancelled') });
        }

        return {
            canExportPerfomaInvoices,
            perfomaInvoiceTitle,
            exportRequest,
            exportDocument,
            history,
            selectItem,
            selectedItem,
            fromDate,
            toDate,
            openModal,
            selectedAll,
            cancelRequest,
            cancelDocuments,
            getDocumentTypeDisplay,
            docs,
            filter,
            filterDocs,
            debounce: createDebounce(),
            statusOptions,
            documentTypeOptions
        };
    }
});
